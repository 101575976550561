import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Cityscape Drive Rentals
			</title>
			<meta name={"description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:title"} content={"Cityscape Drive Rentals"} />
			<meta property={"og:description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11:57:57.501Z) center bottom/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Feel the Pulse of the City
					</Text>
				</StackItem>
			</Stack>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Learn more
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Cityscape Drive Rentals is revolutionizing the way you experience city life.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.
				<br />
				<br />
				Dive into cityscapes with Cityscape Drive Rentals, where we offer a unique fleet of cars designed for both residents and visitors. From agile compact cars for navigating bustling streets to luxurious sedans for refined journeys, our cars are made to make your urban adventures even more exciting.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/about"
			>
				Learn More
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Our gym combines modern equipment and a tranquil environment, providing a unique space where you can focus on your health without distractions. Our team of professional trainers is dedicated to supporting you in achieving your personal fitness goals.
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Why Choose Cityscape Drive Rentals?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11:57:57.530Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-1.jpg?v=2024-02-14T11%3A57%3A57.530Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Handpicked Urban Fleet
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Our handpicked cars are efficient and stylish, ensuring your journey is pleasant and suitable for urban conditions.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11:57:57.490Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/4-4%20%281%29.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Transparent Pricing
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						At Cityscape Drive, you see what you get. Enjoy clear pricing without hidden fees.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11:57:57.496Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-3.jpg?v=2024-02-14T11%3A57%3A57.496Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							24/7 Support
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Our team is always ready and provides assistance 24/7, so you don't have to worry about your city travel.
					</Text>
				</Box>
			</Box>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/about"
			>
				More
			</Button>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						FAQ
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Answers to the most common questions
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What types of cars do you offer?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Our diverse fleet includes compact city cars, ideal for tight spaces and quick trips, luxury sedans for special occasions, as well as spacious SUVs and minivans for group trips.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Do you offer cars for different types of driving?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, we cater to various urban driving needs, including eco-friendly options for those who care about the environment and high-performance cars for those seeking a more thrilling ride.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Can I rent a car on demand?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Absolutely! We strive to accommodate last-minute requests so you can have access to our fleet whenever you need it.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Are there any restrictions on where I can use the car?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							While our cars are designed for city travel, we recommend staying within city limits to ensure the best experience and compliance with our rental agreement terms.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});